<template>
  <div :align="align">
    <img
        v-lazy="` ${image}?w=315&h=200&${imgix_params}`"
        style="
              margin-top: -10px;
              opacity: 0.8;
              z-index: 12;
              box-shadow: 0px 10px 15px 0px rgb(0 0 0.6);
              border-radius: 0;
            "
        v-bind:alt="alt"
        width="315px"
        height="200px"
        class="d-inline-xs d-sm-none"

    />
    <img
        v-lazy="`${image}?w=615&h=400&${imgix_params}`"
        style="
              margin-top: -10px;
              opacity: 0.8;
              z-index: 12;
              box-shadow: 0px 10px 15px 0px rgb(0 0 0.6 );
              border-radius: 0;
            "
        v-bind:alt="alt"
        width="615px"
        class="d-none d-sm-inline"
        height="300px"
    />
  </div>
</template>
<script>
export default {
  name: 'ResponsiveImage',
  computed: {
    imgix_params: {
      get() {
        return this.clamp ? 'fit=clamp' : 'fit=fillmax&fill=blur'
      },
    },
  },
  props: {
    image: {
      type: String,
      default: ''
    },
    clamp: {
      type: Boolean,
      default: false
    },
    align: {
      type: String,
      default: 'text-left'
    },
    alt: {
      type: String,
      default: ''
    },
  }
}
</script>
